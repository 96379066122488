<template>
  <v-row>
    <v-col :cols="$vuetify.breakpoint.smAndDown ? 6 : 3">
      <br>
      <v-btn
        small
        color="primary"
        :to="{ name: 'lot', params: { id: lot.id } }"
      >
        <!-- {{ lot.name }} -->
        View Lot
      </v-btn>
    </v-col>
    <v-col :cols="$vuetify.breakpoint.smAndDown ? 6 : 3">
      <br>
      <v-btn
        small
        :disabled="!fullLot || fullLot.allocations.length === 0"
        @click="unallocateDialog = true"
        color="primary"
      >
        Unallocate
      </v-btn>
      <base-dialog
        v-model="unallocateDialog"
        :headline-text="`Unallocate Lot-${lot.name}`"
        :message-text="`Are you sure you would like to unallocate all from ${lot.name}?`"
        decline-text="Cancel"
        accept-text="Unallocate"
        @accept="unallocateLot()"
      />
    </v-col>
    <v-col :cols="$vuetify.breakpoint.smAndDown ? 6 : 3">
      <br>
      <!--not yet implemented-->
      <v-btn
        color="primary"
        disabled
        small
      >
        Profit Est.
      </v-btn>
    </v-col>
    <v-col :cols="$vuetify.breakpoint.smAndDown ? 6 : 3">
      <small>Change State To</small>
      <br>
      <!--          v-if="$store.getters.can('update-lot-status')"-->
      <status-change-dialog
        v-if="fullLot"
        :status="formatLotStatus()"
        :statuses="lotStatuses"
        :lot="fullLot"
        src-type="lot"
        @updateStatus="updateStatus(nextStatus)"
      >
        <template v-slot:default="props">
          <v-btn
            :loading="loadingStatusChange"
            :disabled="!fullLot || !nextStatus || preventCompletion"
            color="primary"
            v-on="props.on"
            small
          >
            <span>
              {{ (nextStatus) ? nextStatus.text : 'COMPLETED' }}
            </span>
          </v-btn>
        </template>
      </status-change-dialog>
    </v-col>
  </v-row>
</template>

<script>
import StatusChangeDialog from '@/base/components/StatusChangeDialog'
import { mapActions, mapGetters } from 'vuex'
import axios from 'axios'

export default {
  name: 'LotExpandButtons',
  components: { StatusChangeDialog },
  props: {
    lot: {
      type: Object,
      required: true
    },
    allocations: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    loadingStatusChange: false,
    fullLot: null,
    unallocateDialog: false
  }),
  computed: {
    ...mapGetters('lotStore', [
      'lotStatuses'
    ]),
    nextStatus () {
      const keys = this.lotStatuses.map(s => s.value)
      let index = keys.indexOf(this.lot.status)
      if (index !== -1) {
        return this.lotStatuses[index + 1]
      }
      return false
    },
    // Returns true if next lot status is COMPLETE or if any allocation for this lot is not in 'FIN' status
    preventCompletion () {
      if (this.nextStatus.value !== 'CO') return false
      return this.allocations.some((a) => a.status !== 'FIN')
    }
  },
  methods: {
    ...mapActions('hedgeBank', [
      'updateLot',
      'fetchManageable'
    ]),
    async updateStatus (status) {
      // if the status is changed to RECONCILE
      this.loadingStatusChange = true
      await this.updateLot({ lot: this.lot, payload: { status: status.value } })
      await this.fetchManageable()
      this.loadingStatusChange = false
    },
    unallocateLot () {
      const allocationPromises = this.fullLot.allocations.reduce((accu, alloc) => {
        accu.push(axios.delete(`/api/allocations/${alloc.id}`))
        return accu
      }, [])
      Promise.all(allocationPromises).then(() => this.fetchManageable() && this.fetchLot())
    },
    formatLotStatus () {
      const status = this.lot.status
      if (!status) return null
      for (const obj of this.lotStatuses) {
        if (obj.value === status) return obj.text
      }
      return null
    },
    fetchLot () {
      axios.get(`/api/lots/${this.lot.id}/`).then(r => {
        this.fullLot = r.data
      })
    }
  },
  mounted () {
    this.fetchLot()
  }
}
</script>
