<template>
  <unauthorized
    :authorized="$store.getters.can('view-lots')"
    message="Unauthorized to view Hedges"
  >
    <v-container>
      <url-breadcrumbs
        v-if="hedge"
        :items="[
          ['hedge list', { name: 'hedge-list' }],
          hedge.url
        ]"
      />
      <v-row>
        <v-col cols="12">
          <base-title v-if="!loading">
            {{ `Hedge ID #${hedge.id}` }}
            <template slot="subtitle">
              {{ hedge.division.name }}-{{ hedge.designation }}-{{ hedge.smelter.name }}
              <span v-if="selectedYards && selectedYards.length">
                <br>
                <br>
                Hedging for:
                <span
                  v-for="yard in selectedYards"
                  :key="yard.name"
                >
                  <br>
                  {{ yard.name }} | {{ yard.full_address }}
                </span>
              </span>
              <br>
              <br>
              <div class="flex d-flex">
                <div class="pr-2">
                  Created:<br>
                  Expires:<br>
                  Created By:<br>
                  Manager:
                </div>
                <div>
                  {{ hedge.created_at | formatDate }}<br>
                  {{ expiration | formatDate }}<br>
                  {{ hedge.created_by ? hedge.created_by.username : '--' }}<br>
                  {{ hedge.manager__name || '---' }}
                </div>
              </div>
            </template>
          </base-title>
        </v-col>
      </v-row>
      <v-toolbar
        flat
        dense
      >
        <v-spacer />
        <pdf-make-btn
          include-pgm-header
          title="Print"
          :doc-def-builder="pdfDoc"
        />
      </v-toolbar>
      <v-skeleton-loader
        type="card"
        v-if="loading"
      />
      <hedge-toz-table
        v-else
        :hedge="hedge"
        :allocations="allocations"
      />
      <br>
      <v-card>
        <v-card-title>
          Pieces
        </v-card-title>
        <v-card-text>
          {{ pieces }}
        </v-card-text>
      </v-card>
      <br>
      <v-skeleton-loader
        type="card"
        v-if="loading"
      />
      <hedge-notes-section
        v-else-if="hedge.memo"
        :memo="hedge.memo"
      />
      <br>
      <v-skeleton-loader
        type="card"
        v-if="loading"
      />
      <hedge-lots
        v-else
        :lots="lots"
      />
      <br>
      <v-skeleton-loader
        type="card"
        v-if="loading"
      />
      <v-card v-else>
        <v-card-subtitle>
          Smelter
        </v-card-subtitle>
        <v-row>
          <card-list-item title="Name">
            {{ hedge.smelter | filterObject('name') | filterNA }}
          </card-list-item>
          <card-list-item title="Phone Number">
            {{ hedge.smelter | filterObject('phone_number') | filterNA }}
          </card-list-item>
        </v-row>
        <v-row>
          <card-list-item title="Email">
            {{ hedge.smelter | filterObject('email') | filterNA }}
          </card-list-item>
        </v-row>
      </v-card>
      <v-btn
        v-if="this.$store.getters.can('update-hedge')"
        dark
        fab
        fixed
        bottom
        right
        @click="editDialog = !editDialog"
      >
        <v-icon>mdi-pencil-outline</v-icon>
      </v-btn>
    </v-container>
    <edit-dialog
      v-model="editDialog"
      headline-text="Edit Hedge"
      @accept="saveHedge"
    >
      <v-form
        v-model="valid"
        @submit.prevent=""
      >
        <v-select
          label="Smelter"
          v-model="smelterId"
          :items="smelters"
        />
        <search-autocomplete
          v-model="selectedYards"
          label="Yard Search"
          api-path="/api/yards/"
          :params="{ fields: 'id,url,name,full_address,status' }"
          :format-entry="formatAddress"
          multiple
          clearable
        />
        <v-autocomplete
          label="Lot Search"
          v-model="lots"
          :search-input.sync="search"
          :items="combinedLotItems"
          :item-text="x => x.name"
          return-object
          chips
          multiple
        >
          <template v-slot:selection="data">
            <v-chip
              close
              @click="data.select"
              @click:close="removeChip(data.item)"
            >
              {{ data.item.name }}
            </v-chip>
          </template>
        </v-autocomplete>
        <v-text-field
          v-if="!editAccountManager && this.hedge.manager__name"
          label="Account Manager"
          :value="this.hedge.manager__name"
          readonly
          @click="editAccountManager = !editAccountManager"
        />
        <search-autocomplete
          v-else
          :params="{ fields: 'username,id,url', ordering: 'username', active: true }"
          label="Account Manager"
          api-path="/api/users/"
          item-value="url"
          :format-entry="x => ({ friendlyName: x.username, ...x})"
          @input="manager = $event"
        />
        <v-select
          label="Division"
          v-model="divisionId"
          :items="divisions"
        />
        <v-select
          label="Designation"
          v-model="designation"
          :items="this.$store.getters['lotStore/lotTypes']"
        />
        <v-row>
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="ptInitialOz"
              label="PT Initial TOZ"
            />
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="ptRate"
              prepend-inner-icon="mdi-currency-usd"
              label="PT Rate per TOZ"
            />
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="ptReference"
              label="PT Reference"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="pdInitialOz"
              label="PD Initial TOZ"
            />
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="pdRate"
              prepend-inner-icon="mdi-currency-usd"
              label="PD Rate per TOZ"
            />
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="pdReference"
              label="PD Reference"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="rhInitialOz"
              label="RH Initial TOZ"
            />
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="rhRate"
              prepend-inner-icon="mdi-currency-usd"
              label="RH Rate per TOZ"
            />
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="rhReference"
              label="RH Reference"
            />
          </v-col>
        </v-row>
        <rules-text
          label="Pieces"
          type="number"
          v-model="pieces"
        />
        <v-textarea
          label="Hedge Memo"
          v-model="memo"
        />
        <date-input
          label="Created Date"
          v-model="createdAt"
        />
        <date-input
          label="Expiration Date"
          v-model="expiration"
        />
      </v-form>
    </edit-dialog>
  </unauthorized>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import HedgeTozTable from './components/HedgeTozTable'
import HedgeNotesSection from './components/HedgeNotesSection'
import SearchAutocomplete from '@/base/components/SearchAutocomplete'
import HedgeLots from './components/HedgeLots'
import PdfMakeBtn from '@/base/components/PdfMakeBtn.vue'
import RulesText from '@/components/ValidationFields/RulesText'
import { hedgeDocDef } from '@/helpers/pdfMake/hedgeDocDef.js'
import axios from 'axios'

export default {
  name: 'Hedge',
  components: {
    HedgeLots,
    HedgeNotesSection,
    HedgeTozTable,
    SearchAutocomplete,
    PdfMakeBtn,
    RulesText
  },
  data: () => ({
    valid: false, // no fields currently need validation
    editDialog: false,
    loading: true,
    memo: null,
    createdAt: null,
    expiration: null,
    smelterId: null,
    divisionId: null,
    designation: null,
    lazyLoaded: false,
    selectedYards: [],
    lots: null,
    selectableLots: [],
    ptInitialOz: 0,
    pdInitialOz: 0,
    rhInitialOz: 0,
    ptRate: 0,
    pdRate: 0,
    rhRate: 0,
    pieces: 0,
    smelters: [],
    divisions: [],
    designations: [],
    search: null,
    timeout: null,
    ptReference: '',
    pdReference: '',
    rhReference: '',
    editAccountManager: false,
    manager: null
  }),
  watch: {
    editDialog (value) {
      if (value) {
        this.fetchOptions()
        this.editAccountManager = false
      }
    },
    search (val) {
      if (!val) {
        this.selectableLots = []
        return
      }
      this.fetchResults(val)
    }
  },
  computed: {
    ...mapGetters({
      hedge: 'hedgeStore/hedge'
    }),
    /**
     * Combines the selectable lots with existing lots
     * @returns {*[]}
     */
    combinedLotItems () {
      return this.selectableLots.concat(this.lots)
    },
    /**
     * Gets the hedge allocations or returns empty array
     * @returns {*[]|*}
     */
    allocations () {
      if (!this.hedge?.allocations.length) return []
      return this.hedge.allocations.filter(a => a.status !== 'HIS')
    }
  },
  methods: {
    ...mapActions('hedgeStore', [
      'fetchHedge',
      'editHedge'
    ]),
    // this function returns a pdfmake doc template from data that is passed into it which can then be printed
    pdfDoc () {
      // if there is a yard and there is a friendlyName property on the yard, then add it to objProperties)
      const config = {
        hedge: this.hedge,
        expiration: this.expiration,
        createdAt: this.createdAt,
        designation: this.designation,
        allocations: this.allocations,
        ptRate: this.ptRate,
        pdRate: this.pdRate,
        rhRate: this.rhRate
      }
      if (this?.yard?.friendlyName) {
        config.friendlyName = this.yard
      }
      return hedgeDocDef(config)
    },
    fetchOptions () {
      if (!this.lazyLoaded) {
        axios.get('/api/divisions/?page_size=0&fields=name,id').then(response => {
          this.divisions = response.data.map(d => ({ text: d.name, value: d.id }))
        })
        axios.get('/api/smelters/?page_size=0&fields=id,name').then(r => {
          this.smelters = r.data.map(sm => ({ text: sm.name, value: sm.id }))
        })
      }
    },
    // this function formats the autocomplete search results to add an address to the result
    formatAddress (x) {
      return Object.assign(x, { friendlyName: `${x.name} | ${x.full_address} | ${x.status}` })
    },
    removeChip (item) {
      this.lots = this.lots.filter(chip => chip.id !== item.id)
    },
    fetchResults (val) {
      if (this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(async () => {
        const params = { search: val }
        const response = await axios.get('/api/lots/', { params })
        this.selectableLots = response.data.results
      })
    },
    async saveHedge () {
      let payload = {
        id: this.hedge.id,
        memo: this.memo,
        smelter_id: this.smelterId,
        division_id: this.divisionId,
        designation: this.designation,
        designated_hedges: this.lots.map(lot => lot.url),
        pt_initial_toz: this.ptInitialOz,
        pd_initial_toz: this.pdInitialOz,
        rh_initial_toz: this.rhInitialOz,
        pt_rate_per_toz: this.ptRate,
        pd_rate_per_toz: this.pdRate,
        rh_rate_per_toz: this.rhRate,
        pt_reference: this.ptReference,
        pd_reference: this.pdReference,
        rh_reference: this.rhReference,
        // this should be adjusted so that the date saved is UTC 0
        created_at: this.createdAt + 'T00:00:00-07:00',
        expires_on: this.expiration + 'T00:00:00-07:00',
        manager: this.manager,
        pieces: this.pieces
      }
      payload.yards = this.selectedYards.map(y => y.url)
      await this.editHedge(payload)
      this.editDialog = false
    },
    async init () {
      this.lots = this.hedge.designated_lots_details
      this.memo = this.hedge.memo
      this.smelterId = this.hedge.smelter.id
      this.divisionId = this.hedge.division.id
      this.designation = this.hedge.designation
      this.ptInitialOz = this.hedge.pt_initial_toz
      this.pdInitialOz = this.hedge.pd_initial_toz
      this.rhInitialOz = this.hedge.rh_initial_toz
      this.ptRate = this.hedge.pt_rate_per_toz
      this.pdRate = this.hedge.pd_rate_per_toz
      this.rhRate = this.hedge.rh_rate_per_toz
      this.ptReference = this.hedge.pt_reference
      this.pdReference = this.hedge.pd_reference
      this.rhReference = this.hedge.rh_reference
      this.createdAt = new Date(this.hedge.created_at).toISOString().substr(0, 10)
      this.expiration = new Date(this.hedge.expires_on).toISOString().substr(0, 10)
      this.manager = this.hedge.manager
      this.pieces = this.hedge.pieces
      if (this.hedge.yards && this.hedge.yards.length) {
        this.selectedYards = this.hedge.yard_details
      }
    }
  },
  async mounted () {
    await this.fetchHedge(this.$route.params.id)
    this.init()
    this.loading = false
  }
}
</script>
