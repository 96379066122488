<template>
  <unauthorized
    :authorized="$store.getters.can('view-reports')"
    message="Unauthorized to view Reports"
  >
    <base-title title="Hedge Report">
      <info-tooltip>
        <li>
          Click the
          <v-icon>
            mdi-collage
          </v-icon> button in the bottom right to specify filters.
        </li>
        <li>
          If the Hedge has more than two yards, the number of yards will be displayed and you can click on the row to view all the included yards.
        </li>
        <li>
          The Totals/Averages are calculated for all Hedges that meet the filter critera.
        </li>
      </info-tooltip>
    </base-title>
    <hedge-report-filters
      @fetchHedges="fetchReportHedges"
    />
    <v-data-table
      :headers="headers"
      :items="getReportHedges"
      :footer-props="{ 'items-per-page-options': [getHedgesPerPage] }"
      no-data-text="No Hedges found"
      hide-default-footer
      disable-sort
      single-expand
      :expanded.sync="expanded"
      @click:row="expandRow"
    >
      <template v-slot:item.id="{ item }">
        <router-link-id
          :id="item.id"
          name="hedge"
        />
      </template>
      <template v-slot:item.yards="{ item }">
        {{ 3 > item.yard_names.length ? item.yard_names.join(', ') : item.yard_names.length }}
      </template>
      <template v-slot:item.created_at="{ item }">
        {{ item.created_at | formatDate }}
      </template>
      <template v-slot:item.pt_initial_toz="{ item }">
        <div class="pt-color text-right">
          {{ item.pt_initial_toz | formattedToz }}
        </div>
      </template>
      <template v-slot:item.pt_rate_per_toz="{ item }">
        <div class="pt-color text-right">
          {{ item.pt_rate_per_toz | numberToDollar }}
        </div>
      </template>
      <template v-slot:item.pt_total="{ item }">
        <div class="pt-color text-right">
          {{ item.pt_initial_toz * item.pt_rate_per_toz | numberToDollar }}
        </div>
      </template>
      <template v-slot:item.pd_initial_toz="{ item }">
        <div class="pd-color text-right">
          {{ item.pd_initial_toz | formattedToz }}
        </div>
      </template>
      <template v-slot:item.pd_rate_per_toz="{ item }">
        <div class="pd-color text-right">
          {{ item.pd_rate_per_toz | numberToDollar }}
        </div>
      </template>
      <template v-slot:item.pd_total="{ item }">
        <div class="pd-color text-right">
          {{ item.pd_initial_toz * item.pd_rate_per_toz | numberToDollar }}
        </div>
      </template>
      <template v-slot:item.rh_initial_toz="{ item }">
        <div class="rh-color text-right">
          {{ item.rh_initial_toz | formattedToz }}
        </div>
      </template>
      <template v-slot:item.rh_rate_per_toz="{ item }">
        <div class="rh-color text-right">
          {{ item.rh_rate_per_toz | numberToDollar }}
        </div>
      </template>
      <template v-slot:item.rh_total="{ item }">
        <div class="rh-color text-right">
          {{ item.rh_initial_toz * item.rh_rate_per_toz | numberToDollar }}
        </div>
      </template>
      <template v-slot:expanded-item="{ item }">
        <td
          v-if="item.yard_names.length > 2"
          :colspan="headers.length"
          class="pa-4"
        >
          <v-sheet class="ma-4">
            Included Yards:<br>
            <b>
              {{ item.yard_names.join(', ') }}
            </b>
          </v-sheet>
        </td>
      </template>
      <template slot="body.append">
        <tr style="background-color:#EEEEEE">
          <td class="font-weight-bold">
            Totals<br>
            Averages
          </td>
          <td colspan="4" />
          <td class="pt-color text-right">
            T {{ getHedgeData.pt_initial_toz__sum | formattedToz }} <br>A {{ getHedgeData.pt_initial_toz__avg | formattedToz }}
          </td>
          <td class="pt-color text-right">
            A {{ getHedgeData.pt_rate_per_toz__avg | numberToDollar }}
          </td>
          <td class="pt-color text-right">
            T {{ getHedgeData.pt_total_value__sum | numberToDollar }} <br>A {{ getHedgeData.pt_total_value__avg | numberToDollar }}
          </td>
          <td class="pd-color text-right">
            T {{ getHedgeData.pd_initial_toz__sum | formattedToz }} <br>A {{ getHedgeData.pd_initial_toz__avg | formattedToz }}
          </td>
          <td class="pd-color text-right">
            A {{ getHedgeData.pd_rate_per_toz__avg | numberToDollar }}
          </td>
          <td class="pd-color text-right">
            T {{ getHedgeData.pd_total_value__sum | numberToDollar }} <br>A {{ getHedgeData.pd_total_value__avg | numberToDollar }}
          </td>
          <td class="rh-color text-right">
            T {{ getHedgeData.rh_initial_toz__sum | formattedToz }} <br>A {{ getHedgeData.rh_initial_toz__avg | formattedToz }}
          </td>
          <td class="rh-color text-right">
            A {{ getHedgeData.rh_rate_per_toz__avg | numberToDollar }}
          </td>
          <td class="rh-color text-right">
            T {{ getHedgeData.rh_total_value__sum | numberToDollar }} <br>A {{ getHedgeData.rh_total_value__avg | numberToDollar }}
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-row>
      <v-col cols="10">
        <v-pagination
          class="mr-n4"
          :length="Math.ceil(getReportHedgeCount / getHedgesPerPage)"
          circle
          total-visible="15"
          :value="getReportHedgePage"
          @input="handlePageChange"
        />
      </v-col>
      <v-col cols="2">
        <v-select
          label="Rows Per Page"
          :items="[10, 20, 50, 100]"
          :value="getHedgesPerPage"
          @input="handleLimitChange"
        />
      </v-col>
    </v-row>
  </unauthorized>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import HedgeReportFilters from './components/HedgeReportFilters'
export default {
  name: 'HedgeReport',
  components: {
    HedgeReportFilters
  },
  data: () => ({
    headers: [
      { text: 'Hedge ID', value: 'id' },
      { text: 'Smelter ID', value: 'reference' },
      { text: 'Yards', value: 'yards' },
      { text: 'Created', value: 'created_at' },
      { text: 'Smelter', value: 'smelter_name' },
      { text: 'Designation', value: 'designation' },
      { text: 'Pt Initial Toz', value: 'pt_initial_toz' },
      { text: 'Pt Rate Per Toz', value: 'pt_rate_per_toz' },
      { text: 'Pt Total', value: 'pt_total' },
      { text: 'Pd Initial Toz', value: 'pd_initial_toz' },
      { text: 'Pd Rate Per Toz', value: 'pd_rate_per_toz' },
      { text: 'Pd Total', value: 'pd_total' },
      { text: 'Rh Initial Toz', value: 'rh_initial_toz' },
      { text: 'Rh Rate Per Toz', value: 'rh_rate_per_toz' },
      { text: 'Rh Total', value: 'rh_total' }
    ],
    expanded: []
  }),
  filters: {
    numberToDollar (item) {
      if (item) {
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(item)
      }
      return '$0.00'
    },
    formattedToz (item) {
      if (item) {
        return new Intl.NumberFormat('en-US', { style: 'decimal', maximumFractionDigits: 3, minimumFractionDigits: 3 }).format(item) + ' toz'
      }
      return '0.00 toz'
    }
  },
  methods: {
    ...mapActions({
      fetchReportHedges: 'hedgeStore/fetchReportHedges',
      fetchAllHedges: 'hedgeStore/fetchHedgeData'
    }),
    ...mapMutations('hedgeStore', [
      'setReportHedgePage',
      'setHedgesPerPage'
    ]),
    // Page
    handlePageChange (value) {
      this.setReportHedgePage(value)
      this.fetchReportHedges()
    },
    // For items per page
    handleLimitChange (value) {
      this.setHedgesPerPage(value)
      this.fetchReportHedges()
    },
    expandRow (row) {
      const lastClick = this.expanded.pop()
      if (!lastClick || lastClick !== row) this.expanded.push(row)
    }
  },
  computed: {
    ...mapGetters('hedgeStore', [
      'getReportHedges',
      'getReportHedgePage',
      'getReportHedgeCount',
      'getHedgesPerPage',
      'getHedgeData'
    ])
  },
  mounted () {
    this.fetchReportHedges()
  }
}
</script>
