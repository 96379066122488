<!--DEPRECATED-->
<template>
  <v-container fluid>
    <base-title>
      Current Purchases Export
      <info-tooltip>
        Purchases based on those without Lots and Purchases with Lots attached as Non OEM/AM/DIESEL/GENERIC/BEAD
      </info-tooltip>
    </base-title>
    <app-loading-indicator
      v-if="loading"
    />
    <div
      class="mb-12"
      v-else
    >
      <export-current-purchases
        v-if="checkIns && checkIns.length > 0"
        :check-ins="checkIns"
      />
      <filters-dialog />
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ExportCurrentPurchases from './ExportCurrentPurchases'
import FiltersDialog from './FiltersDialog'
import _ from 'underscore'

export default {
  name: 'CurrentPurchasesSection',
  components: { ExportCurrentPurchases, FiltersDialog },
  data: () => ({
    loading: true
  }),
  methods: {
    ...mapActions({
      fetchPieceCountPurchases: 'currentPurchases/fetchPieceCount',
      fetchDivisions: 'divisionStore/fetchDivisions'
    })
  },
  computed: {
    ...mapGetters({
      divisions: 'divisionStore/divisions',
      designations: 'lotStore/lotTypes',
      checkIns: 'currentPurchases/checkIns'
    }),
    groupedCheckIns () {
      return _.groupBy(this.checkIns, 'locationStatus')
    }
  },
  mounted () {
    this.loading = true
    Promise.all([
      this.fetchPieceCountPurchases({
        fields: [
          'created_at',
          'completed_at',
          'id',
          'status',
          'purchase',
          'current_location',
          'active_transit',
          'created_by',
          'yard',
          'designation',
          'total_counts',

          'url',
          'yard_name',
          'yard_id',
          'invoiced',
          'created_by',
          'purchase',
          'preliminary_skid_count',
          'skids',
          'transit_text',
          'transit_destination'
        ].join(',')
      }),
      this.fetchDivisions()
    ]).then(() => {
      this.loading = false
    })
  }
}
</script>
