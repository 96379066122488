<template>
  <v-card>
    <v-card-subtitle>
      Hedge Toz
    </v-card-subtitle>
    <v-card-text>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th />
              <th />
              <th class="text-right">
                Platinum (toz)
              </th>
              <th class="text-right">
                Palladium (toz)
              </th>
              <th class="text-right">
                Rhodium (toz)
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(row, index) in hedgedRows"
              :key="index"
            >
              <td
                colspan="2"
              >
                {{ row[0] }}
              </td>
              <td class="pt-color text-right">
                {{ row[1] | filterNA }}
              </td>
              <td class="pd-color text-right">
                {{ row[2] | filterNA }}
              </td>
              <td class="rh-color text-right">
                {{ row[3] | filterNA }}
              </td>
            </tr>
            <br>
            <tr>
              <td
                colspan="1"
              >
                Lot
              </td>
              <td
                class="text-center"
                colspan="1"
              >
                Status
              </td>
              <td
                colspan="3"
              />
            </tr>
            <template v-if="allocations.length">
              <tr
                v-for="(row) in allocations"
                :key="row.lot.id"
              >
                <td>
                  <router-link
                    class="blue--text"
                    :to="{ name: 'lot', params: { id: row.lot.id }}"
                  >
                    {{ row.lot.name }}
                  </router-link>
                </td>
                <td class="text-center">
                  {{ row.status }}
                </td>
                <td class="text-right pt-color">
                  {{ row.pt }}
                </td>
                <td class="text-right pd-color">
                  {{ row.pd }}
                </td>
                <td class="text-right rh-color">
                  {{ row.rh }}
                </td>
              </tr>
            </template>
            <v-card-subtitle v-else>
              No Allocations Found
            </v-card-subtitle>
            <tr>
              <td
                class="font-weight-bold"
              >
                Remaining (toz)
              </td>
              <td />
              <td
                class="text-right pt-color"
              >
                {{ hedge.pt_estimated_toz }}
              </td>
              <td
                class="text-right pd-color"
              >
                {{ hedge.pd_estimated_toz }}
              </td>
              <td
                class="text-right rh-color"
              >
                {{ hedge.rh_estimated_toz }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
// used to format numbers into currency
const CURRENCY_USD = new Intl.NumberFormat('en-US',
  { style: 'currency', currency: 'USD' }
)
export default {
  name: 'HedgeTozTable',
  props: {
    hedge: {
      type: Object,
      required: true
    },
    allocations: {
      type: Array,
      required: true
    }
  },
  data: function () {
    return {
      allocationTableHeaders: [
        { text: 'Allocations', sortable: false, value: 'lot' },
        { text: '', sortable: false, value: 'status' },
        { text: '', sortable: false, value: 'pt' },
        { text: '', sortable: false, value: 'pd' },
        { text: '', sortable: false, value: 'rh' }
      ]
    }
  },
  computed: {
    hedgedRows () {
      let rows = [
        [
          'Hedge ID',
          this.hedge.pt_reference,
          this.hedge.pd_reference,
          this.hedge.rh_reference
        ],
        [
          'Hedged Metals',
          this.hedge.pt_initial_toz,
          this.hedge.pd_initial_toz,
          this.hedge.rh_initial_toz
        ],
        [
          'Hedged Prices',
          CURRENCY_USD.format(this.hedge.pt_rate_per_toz),
          CURRENCY_USD.format(this.hedge.pd_rate_per_toz),
          CURRENCY_USD.format(this.hedge.rh_rate_per_toz)
        ],
        [
          'Hedged Metal Value',
          CURRENCY_USD.format(this.hedge.pt_total_value),
          CURRENCY_USD.format(this.hedge.pd_total_value),
          CURRENCY_USD.format(this.hedge.rh_total_value)
        ],
        [
          'Allocated Value (approx)',
          CURRENCY_USD.format(this.totalAllocationValue('pt')),
          CURRENCY_USD.format(this.totalAllocationValue('pd')),
          CURRENCY_USD.format(this.totalAllocationValue('rh'))
        ],
        [
          'Hedged Available Value',
          CURRENCY_USD.format(this.hedge.pt_available_value),
          CURRENCY_USD.format(this.hedge.pd_available_value),
          CURRENCY_USD.format(this.hedge.rh_available_value)
        ]
      ]
      return rows
    }
  },
  methods: {
    // gets the value of metals that have been hedged
    totalAllocationValue (metal) {
      metal = metal.toLowerCase()
      const ounces = this.allocations.reduce((accu, alloc) => accu + parseFloat(alloc[metal]), 0)
      return ounces * this.ratePerOunce(metal)
    },
    // gets the rate of each metal in the hedge
    ratePerOunce (metal) {
      const key = metal + '_rate_per_toz'
      if (!this.hedge[key]) return 0
      return this.hedge[key]
    }
  }
}
</script>
