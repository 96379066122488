<template>
  <unauthorized
    class="pb-8"
    :authorized="$store.getters.can('create-hedge')"
    message="Unauthorized to create Hedges"
  >
    <app-loading-indicator v-if="loading" />
    <v-container v-else>
      <base-title
        title="Create Hedge"
      />
      <v-sheet class="pa-4">
        <ValidationObserver
          v-slot="{ invalid }"
          ref="validationObserver"
        >
          <v-form
            ref="form"
            @submit.prevent=""
          >
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <ValidationProvider
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-radio-group
                    label="Smelter*"
                    v-model="smelter"
                    :error-messages="errors"
                  >
                    <v-radio
                      v-for="lt in smelters.filter(s => s.is_active)"
                      :key="lt.id"
                      :label="lt.name"
                      :value="lt.id"
                    />
                  </v-radio-group>
                </ValidationProvider>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <ValidationProvider
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-radio-group
                    label="Designation*"
                    v-model="designation"
                    :error-messages="errors"
                  >
                    <v-radio
                      v-for="lt in designations"
                      :key="lt.value"
                      :label="lt.text"
                      :value="lt.value"
                    />
                  </v-radio-group>
                </ValidationProvider>
                <ValidationProvider
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-radio-group
                    label="Division*"
                    v-model="division"
                    :error-messages="errors"
                  >
                    <v-radio
                      v-for="division in divisions"
                      :key="division.id"
                      :label="division.name"
                      :value="division.id"
                    />
                  </v-radio-group>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <search-autocomplete
                  v-model="selectedYards"
                  label="Yard Search"
                  api-path="/api/yards/"
                  :params="{ fields: 'id,url,name,full_address,status' }"
                  :format-entry="formatAddress"
                  multiple
                  clearable
                />
                <v-autocomplete
                  label="Lot Search"
                  v-model="lots"
                  :search-input.sync="search"
                  :items="combinedItems"
                  :item-text="x => x.name"
                  return-object
                  chips
                  small-chips
                  deletable-chips
                  multiple
                />
                <search-autocomplete
                  v-model="manager"
                  :params="{ fields: 'username,id,url', ordering: 'username', active: true }"
                  label="Account Manager Search"
                  api-path="/api/users/"
                  item-value="url"
                  :format-entry="x => ({ friendlyName: x.username, ...x})"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <date-input
                  v-model="expires"
                  label="Expiration Date"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-checkbox
                  v-model="useTodayAsCreatedDate"
                  small
                  label="Use Today as Creation Date"
                />
                <date-input
                  v-if="!useTodayAsCreatedDate"
                  v-model="createdDate"
                  label="Created Date"
                />
              </v-col>
            </v-row>
            <v-sheet
              class="pa-2 my-4"
              style="border: solid 3px #0068b9;"
              border
              rounded="lg"
            >
              <span class="pt-color">
                Platinum
              </span>
              <v-row>
                <v-col
                  cols="12"
                  md="4"
                >
                  <ValidationProvider
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      label="Troy Ounces*"
                      type="number"
                      step="0.01"
                      min="0"
                      v-model="ptInitialOz"
                      :error-messages="errors"
                    />
                  </ValidationProvider>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <ValidationProvider
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      label="Rate / toz*"
                      type="number"
                      step="0.01"
                      min="0"
                      v-model="ptRatePerToz"
                      :error-messages="errors"
                    />
                  </ValidationProvider>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <ValidationProvider
                    rules="max:30"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      v-model="ptReference"
                      label="Ref. ID"
                      counter="30"
                      :error-messages="errors"
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>
            </v-sheet>
            <v-sheet
              class="pa-2 my-4"
              style="border: solid 3px #d00e00;"
              border
              rounded="lg"
            >
              <span class="pd-color">
                Palladium
              </span>
              <v-row>
                <v-col
                  cols="12"
                  md="4"
                >
                  <ValidationProvider
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      label="Troy Ounces*"
                      type="number"
                      step="0.01"
                      min="0"
                      v-model="pdInitialOz"
                      :error-messages="errors"
                    />
                  </ValidationProvider>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <ValidationProvider
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      label="Rate / toz*"
                      type="number"
                      step="0.01"
                      min="0"
                      v-model="pdRatePerToz"
                      :error-messages="errors"
                    />
                  </ValidationProvider>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <ValidationProvider
                    rules="max:30"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      v-model="pdReference"
                      label="Ref. ID"
                      counter="30"
                      :error-messages="errors"
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>
            </v-sheet>
            <v-sheet
              class="pa-2"
              style="border: solid 3px #006104;"
              border
              rounded="lg"
            >
              <span class="rh-color">
                Rhodium
              </span>
              <v-row>
                <v-col
                  cols="12"
                  md="4"
                >
                  <ValidationProvider
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      label="Troy Ounces*"
                      type="number"
                      step="0.01"
                      min="0"
                      v-model="rhInitialOz"
                      :error-messages="errors"
                    />
                  </ValidationProvider>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <ValidationProvider
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      label="Rate / toz*"
                      type="number"
                      step="0.01"
                      min="0"
                      v-model="rhRatePerToz"
                      :error-messages="errors"
                    />
                  </ValidationProvider>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <ValidationProvider
                    rules="max:30"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      v-model="rhReference"
                      label="Ref. ID"
                      counter="30"
                      :error-messages="errors"
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>
            </v-sheet>
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <rules-text
                  label="Pieces*"
                  type="number"
                  v-model="pieces"
                />
              </v-col>
            </v-row>
            <ValidationProvider
              rules="max:512"
              v-slot="{ errors }"
              type="text"
            >
              <v-textarea
                v-model="memo"
                counter
                :rows="4"
                auto-grow
                maxlength="512"
                label="Memo"
                :error-messages="errors"
              />
            </ValidationProvider>
            <text-fab
              :disabled="invalid"
              bottom
              right
              fixed
              icon-text="mdi-invoice-text-plus"
              color="primary"
              @click="createHedge"
            >
              Create
            </text-fab>
          </v-form>
        </ValidationObserver>
      </v-sheet>
    </v-container>
  </unauthorized>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import SearchAutocomplete from '@/base/components/SearchAutocomplete'
import axios from 'axios'
import RulesText from '@/components/ValidationFields/RulesText'
import { fetchAllDivisions } from '@/requests/divisions'

export default {
  name: 'CreateHedge',
  components: { SearchAutocomplete, RulesText },
  data: (vm) => ({
    manager: null,
    loading: true,
    ptReference: '',
    pdReference: '',
    rhReference: '',
    ptRatePerToz: 0,
    pdRatePerToz: 0,
    rhRatePerToz: 0,
    ptInitialOz: 0,
    pdInitialOz: 0,
    rhInitialOz: 0,
    pieces: 0,
    memo: '',
    expires: new Date().toISOString().substr(0, 10),
    smelter: null,
    yardUrl: null,
    lots: [],
    selectableLots: [],
    selectedYards: [],
    search: null,
    division: null,
    designation: null,
    useTodayAsCreatedDate: true,
    createdDate: new Date().toISOString().substr(0, 10),
    timeout: null,
    divisions: []
  }),
  watch: {
    search (val) {
      if (!val) {
        this.selectableLots = []
        return
      }
      this.fetchResults(val)
    }
  },
  methods: {
    ...mapActions('hedgeStore', [
      'editHedge'
    ]),
    async createHedge () {
      this.loading = true
      const {
        ptReference,
        pdReference,
        rhReference,
        ptRatePerToz,
        pdRatePerToz,
        rhRatePerToz,
        ptInitialOz,
        pdInitialOz,
        rhInitialOz,
        expires,
        smelter,
        lots,
        division,
        memo,
        designation,
        manager,
        pieces
      } = this
      const body = {
        designation,
        designated_hedges: lots.map(lot => lot.url), // map over lots to get the URL for each entry
        smelter_id: smelter,
        division_id: division,
        pt_reference: ptReference || '',
        pd_reference: pdReference || '',
        rh_reference: rhReference || '',
        memo,
        pt_available_toz: ptInitialOz,
        pt_initial_toz: ptInitialOz,
        pt_rate_per_toz: ptRatePerToz,
        pd_available_toz: pdInitialOz,
        pd_initial_toz: pdInitialOz,
        pd_rate_per_toz: pdRatePerToz,
        rh_available_toz: rhInitialOz,
        rh_initial_toz: rhInitialOz,
        rh_rate_per_toz: rhRatePerToz,
        expires_on: expires + 'T07:00:00-00:00',
        manager: manager,
        pieces: pieces
      }
      if (this.selectedYards.length) {
        body.yards = this.selectedYards.map(y => y.url)
      }
      this.$store.dispatch('hedgeStore/createHedge', body)
        .then(async (id) => {
          if (this.lots) {
            // if lots were selected, add the new hedge to them so they can be viewed/edited from the lots page
            try {
              // list of patch requests to be executed all at once
              const promises = []
              const selectedLotIDs = this.lots.map(lot => lot.id)
              for (let index in selectedLotIDs) {
                const existingLotDetails = await axios.get(`/api/lots/${selectedLotIDs[index]}`)
                // get the IDs of all of the hedges that already belong to a selected lot
                const existingHedgeIDs = existingLotDetails.data.designated_hedges.map(lot => lot.id)
                // combine the new Hedge ID with the existing hedge IDs, using .concat rather than .push so that variable name matches value
                const allHedgeIDs = existingHedgeIDs.concat([id])
                promises.push(axios.patch(`/api/lots/${selectedLotIDs[index]}/`, { designated_hedges: allHedgeIDs }))
              }
              await Promise.all(promises)
            } catch (err) {
              this.$store.commit('setSnackbarError', 'An error occurred while trying to create the hedge')
            }
          }
          if (this.useTodayAsCreatedDate) {
            this.$router.push({ name: 'hedge', params: { id } })
          } else {
            let payload = {
              id,
              // this should be adjusted so that the date saved is UTC 0
              created_at: this.createdDate + 'T07:00:00-00:00'
            }
            this.editHedge(payload).then(() => {
              this.$router.push({ name: 'hedge', params: { id } })
            })
          }
        })
        .catch(() => {
          this.$store.dispatch('setSnackbarError', 'An error occurred while attempting to create the Hedge.')
        })
        .finally(() => {
          this.loading = false
          this.hedgeDialog = false
        })
    },
    // this function formats the autocomplete search results to add an address to the result
    formatAddress (x) {
      return Object.assign(x, { friendlyName: `${x.name} | ${x.full_address} | ${x.status}` })
    },
    fetchResults (val) {
      if (this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(async () => {
        const params = { search: val }
        const response = await axios.get('/api/lots/', { params })
        this.selectableLots = response.data.results
      }, 300)
    }
  },
  computed: {
    ...mapGetters({
      smelters: 'smelterStore/smelters',
      designations: 'lotStore/lotTypesActive'
    }),
    combinedItems () {
      return this.selectableLots.concat(this.lots)
    }
  },
  async mounted () {
    this.loading = true
    try {
      const divResponse = await fetchAllDivisions({ is_inactive: false, ordering: 'name' })
      this.divisions = divResponse.data
      await this.$store.dispatch('smelterStore/fetchSmelters')
    } catch (err) {
      this.$store.commit('setSnackbarError', err.message)
    }

    this.loading = false
  }
}
</script>
