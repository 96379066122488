<template>
  <unauthorized
    :authorized="$store.getters.can('view-lots')"
    message="Unauthorized to view Hedges"
  >
    <base-title title="Hedge List" />
    <search-filters
      v-model="search"
      :chips="chips"
      @clearChip="clearChipProp"
      @clearFields="clearFields"
      @submitSearch="routePage"
      @advancedOpened="fetchSmelters"
    >
      <date-range-input
        :start-date="startDate"
        :end-date="endDate"
        end-date-label="Expires Before"
        start-date-label="Expires After"
        @alterStartDate="startDate = $event"
        @alterEndDate="endDate = $event"
      />
      <date-range-input
        :start-date="createdBefore"
        :end-date="createdAfter"
        end-date-label="Created Before"
        start-date-label="Created After"
        @alterStartDate="createdBefore = $event"
        @alterEndDate="createdAfter = $event"
      />
      <v-row>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            label="ID"
            v-model="id"
            type="number"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            label="Reference"
            v-model="reference"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
        >
          <v-select
            label="Smelters"
            v-model="smelterSet"
            :items="smelters"
            multiple
            item-text="text"
            item-value="value"
          />
        </v-col>
        <v-col cols="12">
          <search-autocomplete
            v-model="yardName"
            label="Yard Search"
            api-path="/api/yards/"
            item-value="name"
            :params="{ fields: 'name' }"
            :format-entry="x => ({ friendlyName: x.name, name: x.name })"
          />
        </v-col>
        <v-col cols="12">
          <search-autocomplete
            v-model="managerName"
            label="Manager Search"
            api-path="/api/users/"
            item-value="name"
            :params="{ is_active: true, fields: 'username' }"
            :format-entry="x => ({ friendlyName: x.username, username: x.username })"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="6"
          sm="3"
        >
          <v-text-field
            label="PT Rate From"
            v-model="ptRatePerTozGte"
            prepend-inner-icon="mdi-currency-usd"
            type="number"
          />
        </v-col>
        <v-col
          cols="6"
          sm="3"
        >
          <v-text-field
            label="PT Rate To"
            v-model="ptRatePerTozLte"
            prepend-inner-icon="mdi-currency-usd"
            type="number"
          />
        </v-col>
        <v-col
          cols="6"
          sm="3"
        >
          <v-text-field
            label="From Avail PT"
            v-model="ptAvailableTozGte"
            type="number"
          />
        </v-col>
        <v-col
          cols="6"
          sm="3"
        >
          <v-text-field
            label="To TOZ Avail PT"
            v-model="ptAvailableTozLte"
            type="number"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="6"
          sm="3"
        >
          <v-text-field
            label="PD Rate From"
            v-model="pdRatePerTozGte"
            prepend-inner-icon="mdi-currency-usd"
            type="number"
          />
        </v-col>
        <v-col
          cols="6"
          sm="3"
        >
          <v-text-field
            label="PD Rate To"
            v-model="pdRatePerTozLte"
            prepend-inner-icon="mdi-currency-usd"
            type="number"
          />
        </v-col>
        <v-col
          cols="6"
          sm="3"
        >
          <v-text-field
            label="From Avail PD"
            v-model="pdAvailableTozGte"
            type="number"
          />
        </v-col>
        <v-col
          cols="6"
          sm="3"
        >
          <v-text-field
            label="To TOZ Avail PD"
            v-model="pdAvailableTozLte"
            type="number"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="6"
          sm="3"
        >
          <v-text-field
            label="RH Rate From"
            v-model="rhRatePerTozGte"
            prepend-inner-icon="mdi-currency-usd"
            type="number"
          />
        </v-col>
        <v-col
          cols="6"
          sm="3"
        >
          <v-text-field
            label="RH Rate To"
            v-model="rhRatePerTozLte"
            prepend-inner-icon="mdi-currency-usd"
            type="number"
          />
        </v-col>
        <v-col
          cols="6"
          sm="3"
        >
          <v-text-field
            label="From Avail RH"
            v-model="rhAvailableTozGte"
            type="number"
          />
        </v-col>
        <v-col
          cols="6"
          sm="3"
        >
          <v-text-field
            label="To TOZ Avail RH"
            v-model="rhAvailableTozLte"
            type="number"
          />
        </v-col>
      </v-row>
    </search-filters>
    <v-row>
      <v-col>
        <v-btn
          v-if="$store.getters.can('view-hedge-dump-report')"
          @click="routePageWithChips('hedge-dump-report')"
          color="primary"
          text
        >
          Report/Export
          <v-icon class="ml-2">
            mdi-database-export
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <result-pagination
      v-if="pagination"
      v-model="pagination.page"
      :pages="Math.ceil(totalCount / 20)"
    />
    <v-data-table
      multi-sort
      :headers="headers"
      :items="resultList"
      :server-items-length="totalCount"
      :options.sync="pagination"
      :footer-props="{ 'items-per-page-options': [20] }"
      @page-count="pageCount = $event"
      hide-default-footer
    >
      <template v-slot:item.id="{ item }">
        <router-link-id
          :id="item.id"
          name="hedge"
        />
      </template>
      <template v-slot:item.smelter="{ item }">
        {{ item.smelter.name }}
      </template>
      <template v-slot:item.yard_details="{ item }">
        <span
          v-for="(yard, index) in item.yard_details"
          :key="yard.name"
        >
          {{ yard.name }}<span v-if="index !== (item.yard_details.length - 1)">, <br></span>
        </span>
      </template>
      <template v-slot:item.pt_rate_per_toz="{ item }">
        <div
          v-if="canShow(item.pt_initial_toz)"
          class="pt-color text-right"
        >
          ${{ item.pt_rate_per_toz | toFixed | addCommas }}
        </div>
        <div
          v-else
        >
          --
        </div>
      </template>
      <template v-slot:item.pt_available_toz="{ item }">
        <hedge-data-oz-column
          :can-show="canShow(item.pt_initial_toz)"
          :color="{ 'pt-color': true }"
          :initial="item.pt_initial_toz"
          :available="item.pt_available_toz"
        />
      </template>
      <template v-slot:item.pd_rate_per_toz="{ item }">
        <div
          v-if="canShow(item.pd_initial_toz)"
          class="pd-color text-right"
        >
          ${{ item.pd_rate_per_toz | toFixed | addCommas }}
        </div>
        <div
          v-else
        >
          --
        </div>
      </template>
      <template v-slot:item.pd_available_toz="{ item }">
        <hedge-data-oz-column
          :can-show="canShow(item.pd_initial_toz)"
          :color="{ 'pd-color': true }"
          :initial="item.pd_initial_toz"
          :available="item.pd_available_toz"
        />
      </template>
      <template v-slot:item.rh_rate_per_toz="{ item }">
        <div
          v-if="canShow(item.rh_initial_toz)"
          class="rh-color text-right"
        >
          ${{ item.rh_rate_per_toz | toFixed | addCommas }}
        </div>
        <div
          v-else
        >
          --
        </div>
      </template>
      <template v-slot:item.rh_available_toz="{ item }">
        <hedge-data-oz-column
          :can-show="canShow(item.rh_initial_toz)"
          :color="{ 'rh-color': true }"
          :initial="item.rh_initial_toz"
          :available="item.rh_available_toz"
        />
      </template>
      <template v-slot:item.available_value="{ item }">
        {{ (Number(item.pt_available_value) + Number(item.pd_available_value) + Number(item.rh_available_value)) | currencyUSD }}
      </template>
      <template v-slot:item.expires_on="{ item }">
        {{ item.expires_on | filterNA | formatDate }}
      </template>
      <template v-slot:item.created_at="{ item }">
        {{ item.created_at | filterNA | formatDate }}
      </template>
    </v-data-table>
    <result-pagination
      v-if="pagination"
      v-model="pagination.page"
      :pages="Math.ceil(totalCount / 20)"
    />
    <text-fab
      v-if="$store.getters.can('create-hedge')"
      bottom
      right
      fixed
      icon-text="mdi-plus"
      color="primary"
      @click="$router.push({ name: 'create-hedge' })"
    >
      Create Hedge
    </text-fab>
    <div class="py-8" />
  </unauthorized>
</template>

<script>
import chipsMixin from '@/base/filter/chipsMixin'
import paginationMixin from '@/base/filter/paginationMixin'
import SearchFilters from '@/base/filter/SearchFilters'
import ResultPagination from '@/base/components/ResultPagination'
import SearchAutocomplete from '@/base/components/SearchAutocomplete'
import axios from 'axios'
import HedgeDataOzColumn from './components/HedgeDataOzColumn'

export default {
  mixins: [chipsMixin, paginationMixin],

  name: 'HedgeList',
  components: {
    HedgeDataOzColumn,
    ResultPagination,
    SearchFilters,
    SearchAutocomplete
  },
  data: function () {
    return {
      pageName: 'hedge-list',
      baseUrl: '/api/hedges/',
      fields: [
        'id',
        'smelter',
        'yards',
        'yard_details',
        'pd_available_toz',
        'pd_estimated_toz',
        'pd_initial_toz',
        'pd_rate_per_toz',
        'pt_available_value',
        'pt_available_toz',
        'pt_estimated_toz',
        'pt_initial_toz',
        'pt_rate_per_toz',
        'pd_available_value',
        'rh_available_toz',
        'rh_estimated_toz',
        'rh_initial_toz',
        'rh_rate_per_toz',
        'rh_available_value',
        'expires_on',
        'created_at',
        'manager__name'
      ],

      headers: [
        { text: 'Hedge', sortable: true, value: 'id' },
        { text: 'Smelter', sortable: true, value: 'smelter' },
        { text: 'Yard(s)', sortable: true, value: 'yard_details' },
        { text: 'PT $', sortable: true, value: 'pt_rate_per_toz' },
        { text: 'Platinum', sortable: true, value: 'pt_available_toz' },
        // { text: 'Available', sortable: true, value: 'pt_available_value' },
        { text: 'PD $', sortable: true, value: 'pd_rate_per_toz' },
        { text: 'Palladium', sortable: true, value: 'pd_available_toz' },
        // { text: 'Available', sortable: true, value: 'pd_available_value' },
        { text: 'RH $', sortable: true, value: 'rh_rate_per_toz' },
        { text: 'Rhodium', sortable: true, value: 'rh_available_toz' },
        // { text: 'Available', sortable: true, value: 'rh_available_value', align: 'right' },
        { text: 'Avail. Value', sortable: false, value: 'available_value', align: 'right' },
        { text: 'Manager', sortable: true, value: 'manager__name' },
        { text: 'Total Value', sortable: false, value: 'total_value' },
        { text: 'Expires', sortable: true, value: 'expires_on' },
        { text: 'Created Date', sortable: true, value: 'created_at' }
      ],

      ...this.chipPropagation([
        { name: 'ID', prop: 'id', type: String, query: 'id' },
        { name: 'Manager', prop: 'managerName', type: String, query: 'manager__username' },

        { name: 'Reference', prop: 'reference', type: String, query: 'reference__icontains' },
        { name: 'PT Rate Gte', prop: 'ptRatePerTozGte', type: String, query: 'pt_rate_per_toz__gte' },
        { name: 'PT Rate Lte', prop: 'ptRatePerTozLte', type: String, query: 'pt_rate_per_toz__lte' },
        { name: 'PT Avail Gte', prop: 'ptAvailableTozGte', type: String, query: 'pt_available_toz__gte' },
        { name: 'PT Avail Lte', prop: 'ptAvailableTozLte', type: String, query: 'pt_available_toz__lte' },
        { name: 'PD Rate Gte', prop: 'pdRatePerTozGte', type: String, query: 'pd_rate_per_toz__gte' },
        { name: 'PD Rate Lte', prop: 'pdRatePerTozLte', type: String, query: 'pd_rate_per_toz__lte' },
        { name: 'PD Avail Gte', prop: 'pdAvailableTozGte', type: String, query: 'pd_available_toz__gte' },
        { name: 'PD Avail Lte', prop: 'pdAvailableTozLte', type: String, query: 'pd_available_toz__lte' },
        { name: 'RH Rate Gte', prop: 'rhRatePerTozGte', type: String, query: 'rh_rate_per_toz__gte' },
        { name: 'RH Rate Lte', prop: 'rhRatePerTozLte', type: String, query: 'rh_rate_per_toz__lte' },
        { name: 'RH Avail Gte', prop: 'rhAvailableTozGte', type: String, query: 'rh_available_toz__gte' },
        { name: 'RH Avail Lte', prop: 'rhAvailableTozLte', type: String, query: 'rh_available_toz__lte' },
        // { name: 'Has Allocation', prop: 'withAlloc', type: String, query: 'allocation_set' },

        { name: 'smelters', prop: 'smelterSet', type: Array, query: 'smelter__name__in' },
        { name: 'Yard', prop: 'yardName', type: String, query: 'yards__name' },
        { name: 'Exp. After', prop: 'startDate', type: String, query: 'expires_on__gte' },
        { name: 'Exp. Before', prop: 'endDate', type: String, query: 'expires_on__lte' },
        { name: 'Created After', prop: 'createdAfter', type: String, query: 'created_at__lte' },
        { name: 'Created Before', prop: 'createdBefore', type: String, query: 'created_at__gte' }
      ]),

      smelters: []
    }
  },
  watch: {
    // observes and updated options
    pagination: function (updated) {
      this.routePage(updated)
    }
  },
  methods: {
    canShow (initial) {
      try {
        return !!Number(initial)
      } catch (e) {
        return false
      }
    },
    fetchSmelters () {
      if (this.smelters.length > 0) return
      axios.get('/api/smelters/?page_size=0&fields=id,name').then(r => {
        this.smelters = r.data.map(sm => ({ text: sm.name, value: sm.name }))
      })
    }
  },
  mounted () {
    this.reflectRouteParams()
    this.executeSearch()
  }
}
</script>
